/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-03-11",
    versionChannel: "nightly",
    buildDate: "2023-03-11T00:19:08.742Z",
    commitHash: "f4354a638e952fa4f9d39efd63631ef3daba437f",
};
